<template>
  <div @click="clickHandle">
    <icone
        v-show="direcao === 'next'"
        :cor="corIcone"
        :class="[{ 'light' : !dark },'fas fa-chevron-right']"
        :size="sizeIcone"/>
    <icone
        v-show="direcao === 'prev'"
        :cor="corIcone"
        :class="[{ 'light' : !dark },'fas fa-chevron-left']"
        :size="sizeIcone"/>
  </div>
</template>

<script>
import Icone from '@/components/Carousel/ComponentIcone'

export default {
  name: 'BotaoNavegacao',
  components: {
    Icone
  },
  props: {
    direcao: {
      type: String,
      default: 'next'
    },
    onClick: {
      type: Function,
      required: true
    },
    dark: {
      type: Boolean,
      default: true,
    },
    corIcone:{
      type: String,
      default: ''
    },
    sizeIcone:{
      type: String,
      default: '48px'
    }
  },
  methods: {
    clickHandle: function() {
      if (this.onClick && typeof this.onClick === 'function') {
        this.onClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .light {
    color: white;
  }

</style>
