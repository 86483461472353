<template>
	<!-- Componente de mostra os preços na tela do produto -->
	<div>
		<div class="container-preco">
			<!-- Fora do estado -->
			<div v-if="isInterState">
				<div v-if="cupomAtivo" class="preco-final head-bold color-grey-3c" style="font-size: 16px; line-height: 19px; text-decoration: line-through; color: #6a767d; margin-bottom: 8px !important">
					{{ majorPrice | formatarMoeda }}
				</div>
				<div v-else class="preco-final head-bold color-grey-3c">
					{{ majorPrice | formatarMoeda }}
				</div>
				<div v-if="cupomAtivo" class="preco head-bold color-grey-3c">
					{{ (majorPrice - majorPrice * Number(cupomDesconto)) | formatarMoeda }}
					<span class="head-regular green sem-juros" style="font-size: 16px"
						>usando o cupom <b class="head-bold" style="text-decoration: underline">{{ cupomAtivo }}</b></span
					>
				</div>
			</div>

			<div v-else>
				<div class="desconto-wrapper" v-if="haveDiscount">
					<div class="precode color-grey-6a vw-head-light">
						de: <span class="line-through">{{ finalPrice | formatarMoeda }}</span>
					</div>

					<div class="flex precopor head-bold color-grey-3c">
						<span class="color-grey-6a vw-head-light" style="margin-right: 4px">por:</span> {{ discountPrice | formatarMoeda }}
						<span class="desconto" :class="discountClass" v-if="showDiscountBadge">-{{ discount }}%</span>
					</div>
				</div>

				<!-- Preço sem desconto -->
				<div v-else>
					<div v-if="cupomAtivo" class="preco preco-riscado head-bold color-grey-3c" style="font-size: 16px; line-height: 19px; text-decoration: line-through; color: #6a767d">
						{{ discountPrice | formatarMoeda }}
					</div>
					<div v-else class="preco head-bold color-grey-3c">
						{{ discountPrice | formatarMoeda }}
					</div>
					<div v-if="cupomAtivo" class="preco head-bold color-grey-3c">
						{{ (discountPrice - discountPrice * Number(cupomDesconto)) | formatarMoeda }}
						<span class="head-regular green sem-juros"
							>usando o cupom <b class="head-bold" style="text-decoration: underline">{{ cupomAtivo }}</b></span
						>
					</div>
				</div>
			</div>

			<!-- Retire gratis -->
			<div class="retire__gratis hidden" v-if="vendidoPor">
				<div class="head-bold">
					Retire grátis em: <span class="head-bold color-grey-3c">{{ vendidoPor }}</span>
				</div>
			</div>
			<div class="qtd__stock head-regular color-grey-6a hidden">{{ getStockText(quantity) }}</div>

			<div v-if="showParcelas" class="showparcelas">
				<div class="container-maximo-parcelas" v-if="parcelaData">
					<span class="head-regular em-ate">Em até</span>
					<span class="head-bold de"
						>{{ parcelaData.parcela_maxima }}x de
						<span v-if="isInterState">{{ parcelaData.valor_majorado_parcelado | formatarMoeda }}</span>
						<span v-else>{{ parcelaData.valor_parcelado | formatarMoeda }}</span></span
					>
					<span class="head-regular green sem-juros">sem juros</span>
				</div>
				<a href.prevent="#" class="extra-info head-regular" @click="triggerCondicoesParcelamento">Informações sobre condições de parcelamento</a>
			</div>
		</div>

		<!-- Condições de parcelamento -->
		<Modal :visibility="modalCondicoesParcelamento" @closeModal="modalCondicoesParcelamento = false" width="500">
			<div class="flex flex-column align-items-center static-mt-24">
				<div class="head-bold color-dark text-center font-32 static-mb-16">Condições de parcelamento</div>
				<div class="head-regular font-16 text-center static-mb-16 color-grey-6f">
					O parcelamento máximo pode variar em função do valor de parcela mínima de R$ 20 em produtos e do parcelamento máximo definido por cada concessionária.<br />
					O valor mínimo para compras com PIX é de R$50 por concessionária, somado valor de produtos e frete.
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import { getDealershipsText } from "@/utils/product"
import Modal from "@/components/Common/Modal"
export default {
	components: {
		Modal,
	},
	data() {
		return {
			modalCondicoesParcelamento: false,
		}
	},
	props: {
		vendidoPor: {
			type: String,
		},
		finalPrice: {
			type: Number,
		},
		majorPrice: {
			type: Number,
		},
		discountPrice: {
			type: Number,
		},
		discount: {
			type: Number,
		},
		geolocation: {
			type: Object,
		},
		dealerUf: {
			type: String,
		},
		showParcelas: {
			type: Boolean,
		},
		maximoParcelas: {
			type: Boolean | Number,
		},
		priceClass: {
			type: String,
			default: "font-32 head-bold",
		},
		discountClass: {
			type: String,
			default: "",
		},
		parcelaData: {
			type: Object,
		},
		quantity: {
			type: Number,
		},
		multiplasConcessionarias: {
			type: Boolean,
		},
	},
	methods: {
		getStockText(quantity) {
			return getDealershipsText(quantity)
		},
		triggerCondicoesParcelamento() {
			this.modalCondicoesParcelamento = !this.modalCondicoesParcelamento

			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("verificar-condicoes-parcelamento")
			}
		},
	},
	computed: {
		cupomAtivo() {
			return this.$epecaHubB2c.configJson.CUPOM_ATIVO
		},
		cupomDesconto() {
			return this.$epecaHubB2c.configJson.CUPOM_DESCONTO
		},
		isInterState() {
			if (this.geolocation) {
				if (this.dealerUf === this.geolocation.uf) {
					return false
				}
			}
			return true
		},
		haveDiscount() {
			if (this.finalPrice > this.discountPrice) {
				return true
			}
			return false
		},
		showDiscountBadge() {
			if (this.haveDiscount && this.discount > 0) {
				return true
			}
			return false
		},
	},
}
</script>

<style lang="scss" scoped>
.desconto {
	background-color: #000;
	color: #fff;
	margin-left: 8px;
	display: flex;
	align-items: center;
	padding: 4px 8px;
	font-size: 14px;
	line-height: 14px;
	@media (min-width: 1025px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.product__list {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr;
	&__item {
		padding: 16px;
		border: 1px solid #f1f1f1;
		border-radius: 10px;
		&:hover {
			border: 1px solid var(--primary_color);
		}
		&__action {
			&__local {
				display: flex;
				font-size: 12px;
				line-height: 15px;
				svg {
					height: 16px;
					width: 16px;
					margin-right: 4px;
				}
			}
			&__price {
				font-size: 16px;
				line-height: 19px;
				color: var(--text_dark_color);
				font-weight: bold;
				font-family: "VWHead-Bold", sans-serif;
			}
		}
		&__img {
			width: 100px;
			height: 100px;
			margin: 0 auto 8px;
			background-size: cover;
			border: 1px solid #f1f1f1;
		}
		&__desc {
			margin-bottom: 40px;
			min-height: 60px;
			word-break: break-all;
			font-size: 12px;
			line-height: 15px;
			font-weight: 300;
			font-family: "VWHead-Regular", sans-serif;
		}
	}
}
@media (min-width: 1200px) {
	.list__wrapper {
		display: grid;
		grid-template-columns: 306px auto;
		gap: 30px;
	}
	.product__list {
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr 1fr 1fr;
		&__item {
			padding: 24px;
			border: 1px solid #f1f1f1;
			border-radius: 10px;
			&:hover {
				border: 1px solid var(--primary_color);
			}
			&__action {
				&__local {
					display: flex;
					font-size: 12px;
					line-height: 15px;
				}
				&__price {
					font-size: 16px;
					line-height: 19px;
					color: var(--text_dark_color);
					font-weight: bold;
				}
			}
			&__img {
				width: 224px;
				height: 224px;
				margin: 0 auto 16px;
				background-size: cover;
				border: 1px solid #f1f1f1;
			}
			&__desc {
				margin-bottom: 40px;
				min-height: 80px;
				word-break: break-word;
				font-size: 16px;
				line-height: 19px;
			}
		}
	}
}
.preco {
	font-size: 28px;
	line-height: 34px;
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 39px;
	}
}
.green {
	color: #029640;
}
.em-ate {
	color: #6f6f6f;
	font-size: 16px;
	line-height: 19px;
}
.de {
	font-size: 16px;
	line-height: 19px;
	color: #3c484d;
}
.sem-juros {
	font-size: 16px;
	line-height: 19px;
}
.extra-info {
	font-size: 14px;
	line-height: 17px;
	color: #6f6f6f;
	cursor: pointer;
	text-decoration: underline;
}
.precode {
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 4px;
	@media (min-width: 1025px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.showparcelas {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.box-flutuante {
	.extra-info {
		display: none;
	}
}
.preco-final {
	font-size: 20px;
	line-height: 24px;
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 39px;
	}
}
.precopor {
	font-size: 20px;
	line-height: 24px;
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 16px;
	}
}
.list {
	.preco-final {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.showparcelas {
		display: none;
	}
	.precopor {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 8px;
	}
	.precode {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 4px;
	}
	.desconto {
		font-size: 12px;
		line-height: 12px;
	}
	.qtd__stock {
		display: block;
		font-size: 12px;
		line-height: 15px;
	}
	.preco-riscado {
		font-size: 14px !important;
		line-height: 18px !important;
	}
	.sem-juros {
		font-size: 14px;
		line-height: 18px;
	}
	// Preço carousel home
	.preco {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 4px;
	}
	.retire__gratis {
		display: block;
		div {
			color: #029640;
			font-size: 12px;
			line-height: 15px;
		}
	}
}
.carousel {
	.qtd__stock {
		display: block;
		font-size: 12px;
		line-height: 15px;
	}
}
.productpage__similar {
	.precopor {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 8px;
	}
	.precode {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 4px;
	}
	.desconto {
		font-size: 12px;
		line-height: 12px;
	}
	.preco-final {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.showparcelas {
		display: none;
	}
	.qtd__stock {
		display: block;
		font-size: 12px;
		line-height: 15px;
	}
	.preco {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.retire__gratis {
		display: block;
		div {
			color: #029640;
			font-size: 12px;
			line-height: 15px;
		}
	}
}
.outras-ofertas {
	background-color: #029640;
	border-radius: 8px;
	width: 100%;
	padding: 8px;
	font-size: 16px;
	line-height: 19px;
	color: #fff;
	margin: 6px 0;
	transition: all 0.4s ease-in-out;
	display: inline-block;
	width: inherit;
	svg {
		height: 16px;
		width: 16px;
	}
	button {
		span {
			font-size: 12px;
		}
	}

	&:hover {
		background-color: #036b2e;
	}
	@media (min-width: 768px) {
		padding: 8px 16px;
		svg {
			height: 32px;
			width: 32px;
		}
		button {
			text-align: center;
			span {
				font-size: 14px;
			}
		}
	}
}
.container-preco {
	margin-bottom: 24px;
}
</style>
