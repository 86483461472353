<template>
  <div :class="['component-view-carregando', modo, { 'img-small': imgSmall }]">
    <Loader />
  </div>
</template>

<script>
import Loader from "@/components/Common/Loader.vue";
export default {
  name: "ComponentViewCarregando",
  props: {
    modo: {
      type: String,
      default: "padrao",
    },
    imgSmall: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.component-view-carregando.padrao {
  font-size: 1.6em;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 2em 0;
}

.component-view-carregando.viewport-overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.92);
  font-size: 1.6em;
  font-weight: 400;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000000;
}

.component-view-carregando img {
  height: 4em;
  opacity: 0.75;
}
.component-view-carregando.img-small img {
  height: 2em;
}

.component-view-carregando span {
  color: #666;
  display: inline-block;
  letter-spacing: -0.05em;
  opacity: 0.75;
}
</style>
