<template>
	<div class="product__pricerange" :class="[noMarginBottom ? '' : 'static-mb-16']">
		<div v-if="!sameRange" class="prices head-bold color-grey-3c">
			{{ lowPrice | formatarMoeda }} -
			{{ highPrice | formatarMoeda }}
		</div>

		<div v-else class="prices head-bold color-grey-3c">
			{{ lowPrice | formatarMoeda }}
		</div>

		<div class="stock hidden head-regular color-grey-6a">
			{{ getStockText(quantity) }}
		</div>
	</div>
</template>

<script>
import { getDealershipsText } from "@/utils/product"
export default {
	methods: {
		getStockText(quantity) {
			return getDealershipsText(quantity)
		},
	},
	computed: {
		sameRange() {
			if (this.lowPrice === this.highPrice) {
				return true
			}
		},
	},
	props: {
		lowPrice: {
			type: Number,
		},
		highPrice: {
			type: Number,
		},
		quantity: {
			type: Number,
		},
		priceFeatured: {
			type: Boolean,
			default: false,
		},
		noMarginBottom: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
<style lang="scss" scoped>
.product__pricerange {
	// min-height: 91px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.prices {
	font-size: 16px;
	line-height: 19px;
	font-weight: bold;
	font-family: var(--head-bold);
}
.productpage__price {
	.prices {
		font-size: 28px;
		line-height: 34px;
	}
}
@media (min-width: 1200px) {
	.productpage__price {
		.prices {
			font-size: 32px;
			line-height: 39px;
		}
	}
}

.box-flutuante {
	.product__pricerange {
		margin-bottom: 8px;
	}
}
.productpage__similarprice .stock {
	display: block;
	margin-top: 4px;
	font-size: 12px;
	line-height: 15px;
}
</style>
