<template>
  <div class="">
    <div :class="{ 'container-carousel': true }">
      <div>
        <carousel
          ref="carousel"
          :pagination-enabled="exibirPaginacao"
          :per-page-custom="[[300, 1]]"
          pagination-active-color="#0e408f"
          :pagination-color="retornaCorBotao()"
          :loop="true"
        >
          <slot />
        </carousel>

        <botao-navegacao
          :on-click="prevSlide"
          :dark="botaoSetaDark"
          :cor-icone="corIcone"
          :size-icone="sizeIcone"
          :class="['prev-button', 'nav-button-slide']"
          :style="prevButton"
          direcao="prev"
        />

        <botao-navegacao
          :on-click="nextSlide"
          :dark="botaoSetaDark"
          :cor-icone="corIcone"
          :size-icone="sizeIcone"
          :class="['next-button', 'nav-button-slide']"
          :style="nextButton"
          direcao="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BotaoNavegacao from "@/components/Carousel/ComponentBotaoNavegacao";

export default {
  name: "ComponentCarouselBase",
  components: {
    BotaoNavegacao,
  },
  props: {
    botaoSetaDark: {
      type: Boolean,
      default: true,
    },
    paginacaoDark: {
      type: Boolean,
      default: false,
    },
    exibirEmDesktop: {
      type: Boolean,
      default: false,
    },
    corIcone: {
      type: String,
      default: "",
    },
    nextButton: {
      type: String,
      default: "",
    },
    prevButton: {
      type: String,
      default: "",
    },
    sizeIcone: {
      type: String,
      default: "48px",
    },
    exibirPaginacao: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    retornaCorBotao() {
      return this.paginacaoDark ? "#002868" : "#555";
    },
  },
};
</script>

<style lang="scss" scoped>
.container-carousel {
  // padding: 0 40px;
  position: relative;
}

.slide-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  margin-top: 18px;
}

@media (min-width: 768px) {
  .desktop-off {
    display: none;
  }
}

.prev-button {
  position: absolute;
  top: 30%;
  left: 0px;
  cursor: pointer;
  margin-right: 20px;
}

.next-button {
  position: absolute;
  top: 30%;
  right: -2px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .prev-button {
    left: 0 !important;
  }
  .next-button {
    right: 0 !important;
  }
}

.VueCarousel-slide {
  display: flex;
}

.container-card {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.nav-button-slide i {
  font-size: 48px;
}
</style>
<style lang="scss">
.VueCarousel-dot-button {
  transition: all 0.3s;
}

.VueCarousel-dot--active .VueCarousel-dot-button {
  border-radius: 10px !important;
  width: 30px !important;
  height: 8px !important;
}
</style>
