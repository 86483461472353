<template>
	<div>
		<div class="skeleton__list">
			<div class="skeleton__listitem" v-for="(item, $k) in itemsCount" :key="$k">
				<div class="skeleton__listitem--img"></div>
				<div class="skeleton__listitem--desc">
					<div class="skeleton__listitem--stripe"></div>
					<div class="skeleton__listitem--stripe"></div>
					<div class="skeleton__listitem--stripe"></div>
					<div class="skeleton__listitem--stripe"></div>
				</div>

				<div class="skeleton__listitem--desc">
					<div class="skeleton__listitem--stripe"></div>
					<div class="skeleton__listitem--stripe"></div>
					<div class="skeleton__listitem--stripe"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CarouselSkeleton",
	props: {
		itemsCount: {
			type: Number,
			default: 4,
		},
	},
}
</script>

<style scoped lang="scss">
.skeleton__list {
	display: grid;
	grid-gap: 16px;
	gap: 16px;
	grid-template-columns: 1fr;
	margin: 0 16px;
}
.skeleton__listitem {
	padding: 16px;
	border: 1px solid #f1f1f1;
	border-radius: 10px;
	width: 100%;
	position: relative;
	display: none;
	&:first-of-type {
		display: block;
	}
	&--img {
		height: 129px;
		background-color: #f1f1f1;
		margin-bottom: 16px;
		position: relative;
		overflow: hidden;
		&::before {
			content: "";
			display: block;
			position: absolute;
			left: -150px;
			top: 0;
			height: 100%;
			width: 150px;
			background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
			animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
		}
	}
	&--desc {
		margin-bottom: 32px;
	}
	&--stripe {
		height: 16px;
		background-color: #f1f1f1;
		margin-bottom: 4px;
		position: relative;
		overflow: hidden;
		&::before {
			content: "";
			display: block;
			position: absolute;
			left: -150px;
			top: 0;
			height: 100%;
			width: 150px;
			background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
			animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
@media (min-width: 1200px) {
	.skeleton__list {
		grid-gap: 30px;
		gap: 30px;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin: 0;
	}
	.skeleton__listitem {
		padding: 24px;
		border-radius: 10px;
		width: 100%;
		display: block;
		&--img {
			height: 224px;
			background-color: #f1f1f1;
			margin-bottom: 16px;
		}
		&--desc {
			margin-bottom: 40px;
		}
		&--stripe {
			height: 16px;
			background-color: #f1f1f1;
			margin-bottom: 4px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
@keyframes load {
	from {
		left: -150px;
	}
	to {
		left: 100%;
	}
}
</style>
