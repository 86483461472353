<template>

  <div class="component-icone">
    <i 
      :class="[classe]"
      :style="{'font-size': size, 'color': cor}"
      class="container_icon" >    
    </i>
  </div>

</template>

<script>
export default {
  // FIXME remover props de style
  name: 'Icone',
  props: {
    classe: {
      type: String,
      default: 'material-icons'
    },
    size: {
      type: String,
      default: '16px'
    },
    cor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa-chevron-left:before,
  .fa-chevron-right:before{
     color: #ccc ;
  }
  .container_icon {
    user-select: none;   
  }
  .component-icone{
    border: 1px solid #ccc;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
